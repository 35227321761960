import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo' 

export default function Gerson(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Das Schicksal der jüdischen Familie Gerson aus Oberwesel" />
        <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid mt="60px" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading variant="utitel">Oberwesel, Kirchstraße 85, Gustav und Henriette Gerson – Rosalie und Else Trum, Erna und Max Gottschalk und ihr Sohn Alfred</Heading>
            <Heading variant="utitel">26. September 1935</Heading>
            <Text variant="solid">
            Der 70-jährige Gustav Gerson steht vor dem Amtsgericht St. Goar. Die NSDAP hat Anzeige erstattet wegen einer angeblich betrogenen Witwe aus Perscheid. Der Staatsanwalt beantragt von 300 RM Geldstrafe, um »seinen Rassegenossen als abschreckendes Beispiel zu dienen.« Die Sache wird im Koblenzer Nationalblatt breitgetreten.
            </Text>
            <Heading variant="utitel">Oktober 1935</Heading>
            <Text variant="solid">In dem Hetzblatt Der Stürmer werden unter dem Titel »Der Viehhandel des Juden Gerson« Gustav Gerson und sein Schwiegersohn Max Gottschalk als niederträchtige Betrüger an den Pranger gestellt.</Text>
            <Heading variant="utitel">2. Januar 1937</Heading>
            <Text variant="solid">Else Trum, die gehbehinderte Schwester der adoptierten Erna Gerson, zieht zu Gustav Gerson.</Text>
            <Heading variant="utitel">9. August 1937</Heading>
            <Text variant="solid">Henriette Gerson, geb. Trum, wird auf dem Friedhof bestattet. Sie war von 1921 bis 1924 Stadtverordnete.</Text>
            <Heading variant="utitel">9. März 1938</Heading>
            <Text variant="solid">Max Gottschalk flieht nach einer Warnung des Polizisten Lindstedt nach Rotterdam und weiter nach New York.</Text>
            <Heading variant="utitel">10. November 1938</Heading>
            <Text variant="solid">Mit seinem Enkel Alfred kann Gustav Gerson am Morgen nach der Schändung der Synagoge Teile der Thorarollen aus dem Bach bergen. Er bringt die Gedenktafel für die jüdischen Gefallenen zum Jüdischen Friedhof.</Text>
            <Heading variant="utitel">15. November 1938</Heading>
            <Text variant="solid">Alfred Gottschalk wird von der SA aus der Schulklasse geholt und darf die Schule nicht weiter besuchen.</Text>
            <Heading variant="utitel">1. April 1939</Heading>
            <Text variant="solid">Rosalie Trum, Witwe von August Trum aus Gau-Odernheim, zieht zu Gersons.</Text>
            <Heading variant="utitel">11. Juni 1939 </Heading>
            <Text variant="solid">Erna Gottschalk flieht mit dem 9-jährigen Alfred nach New York, wo sie ihren Mann Max wieder trifft.</Text>
            <Heading variant="utitel">2. November 1939</Heading>
            <Text variant="solid">Gustav Gerson wird aufgefordert, »sich bis zum 15. ds. Mts. eine Wohnung in einem jüdischen Anwesen hier zu suchen.« Er zieht zur Witwe Bertha Gerson, geb. Kahn, in der Simmerner Straße 8a, ebenso Rosalie und Else Trum.</Text>
            <Heading variant="utitel">17. Januar 1940</Heading>
            <Text variant="solid">Rosalie Trum flieht ohne ihre Tochter Else nach Argentinien. Sie stirbt am 09.11.1972 in den USA.</Text>
            <Heading variant="utitel">29. November 1940</Heading>
            <Text variant="solid">Gustav Gerson erleidet bei einer Kontrolle im Bahnhof einem tödlichen Herzschlag. Er wollte nach Köln zu seiner im Krankenhaus liegenden Schwester Mathilde Aumann und war denunziert worden.</Text>
            <Heading variant="utitel">2. April 1942</Heading>
            <Text variant="solid">Else Trum wird zusammen mit den anderen deportierten Juden über Salzig und Koblenz deportiert</Text>
            </Box>
            <Box>
                <Box p="1">
                    <StaticImage src="../images/solo/gerson-else-trum-kirchstr.jpg" alt="Gustav und Henriette Gerson &amp; Else Trum"/>
                    <Text variant="Bild">Gustav Gerson &amp; Else Trum &amp; Rosalie Trum </Text>
                </Box>
               
                <Box p="1">
                    <StaticImage src="../images/solo/gerson-geschaeft.jpg" alt="nzeige Geschäftsverlegung" />
                    <Text variant="Bild">Anzeige Geschäftsverlegung von Gustav Gerson</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/else-trum.jpg" alt="Else Trum in ihrem Garten" />
                    <Text variant="Bild">Else Trum in ihrem Garten in der Kirchstrasse</Text>
                </Box>
            </Box>
            </SimpleGrid>
            </motion.div>
            
    )
}